<template>
  <div class="title_text">
    <h2>{{ titleText.title }}</h2>
    <p :style="{ width: titleText.width }">
      {{ titleText.text }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'title_text',
  props: {
    titleText: {
      type: Object,
      requre: true
    }
  }
}
</script>
<style lang="scss" scoped>
.title_text {
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: #363739;
  }
  > p {
    border-left: 2px solid #fd5a0d;
    padding-left: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #363739;
    margin-top: 10px;
    width: 47%;
  }
}
@media only screen and (max-width: 1040px) {
  .title_text {
    h2 {
      font-size: 33px;
    }
    > p {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 880px) {
  .title_text {
    h2 {
      font-size: 28px;
      line-height: 38px;
    }
    > p {
      font-size: 15px;
    }
  }
}
@media only screen and (max-width: 375px) {
  .title_text {
    h2 {
      font-size: 24px;
      line-height: 29px;
    }
    > p {
      font-size: 13px;
      line-height: 16px;
    }
  }
}
</style>
