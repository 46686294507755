<template>
  <button class="my_button" @click="$emit('openCommentBox')">
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: 'my_button'
}
</script>
<style lang="scss" scoped>
.my_button {
  padding: 12px 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #f5f5f5;
  background: #363739;
  border-radius: 30px;
  border: none;
}
@media only screen and (max-width: 1040px) {
  .my_button {
    padding: 9px 17px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .my_button {
    padding: 12px 20px;
    font-size: 16px;
  }
}
</style>
